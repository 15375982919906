//TypeScript file with the mapping of shortCode to Fullname
//shortcode is the 'owner' in satellites.json, or 'source code' in celestrack. fullName would be the 'Source Description'
export const OWNER_SHORT_CODE_TO_FULL: Record<string, string> =

{
  AB: "Arab Satellite Communications Organization",
  ABS: "Asia Broadcast Satellite",
  AC: "Asia Satellite Telecommunications Company (ASIASAT)",
  ALG: "Algeria",
  ANG: "Angola",
  ARGN: "Argentina",
  ASRA: "Austria",
  AUS: "Australia",
  AZER: "Azerbaijan",
  BEL: "Belgium",
  BELA: "Belarus",
  BERM: "Bermuda",
  BGD: "Bangladesh",
  BHUT: "Bhutan",
  BOL: "Bolivia",
  BRAZ: "Brazil",
  BUL: "Bulgaria",
  CA: "Canada",
  CHBZ: "China/Brazil",
  CHLE: "Chile",
  CIS: "Russia / former Soviet Union (USSR)",
  COL: "Colombia",
  CRI: "Costa Rica",
  CZCH: "Czech Republic (former Czechoslovakia)",
  DEN: "Denmark",
  ECU: "Ecuador",
  EGYP: "Egypt",
  ESA: "European Space Agency (ESA)",
  ESRO: "European Space Research Organization (ESRO)",
  EST: "Estonia",
  EUME: "European Organization for the Exploitation of Meteorological Satellites (EUMETSAT)",
  EUTE: "European Telecommunications Satellite Organization (EUTELSAT)",
  FGER: "France/Germany",
  FIN: "Finland",
  FR: "France",
  FRIT: "France/Italy",
  GER: "Germany",
  GHA: "Ghana",
  GLOB: "Globalstar",
  GREC: "Greece",
  GRSA: "Greece/Saudi Arabia",
  GUAT: "Guatemala",
  HUN: "Hungary",
  IM: "International Mobile Satellite Organization (INMARSAT)",
  IND: "India",
  INDO: "Indonesia",
  IRAN: "Iran",
  IRAQ: "Iraq",
  IRID: "Iridium",
  ISRA: "Israel",
  ISRO: "Indian Space Research Organisation",
  ISS: "International Space Station (ISS)",
  IT: "Italy",
  ITSO: "International Telecommunications Satellite Organization (INTELSAT)",
  JPN: "Japan",
  KAZ: "Kazakhstan",
  KEN: "Kenya",
  LAOS: "Laos",
  LKA: "Sri Lanka",
  LTU: "Lithuania",
  LUXE: "Luxembourg",
  MA: "Morroco",
  MALA: "Malaysia",
  MEX: "Mexico",
  MMR: "Myanmar",
  MNG: "Mongolia",
  MUS: "Mauritius",
  NATO: "North Atlantic Treaty Organization (NATO)",
  NETH: "Netherlands",
  NICO: "New ICO",
  NIG: "Nigeria",
  NKOR: "North Korea",
  NOR: "Norway",
  NPL: "Nepal",
  NZ: "New Zealand",
  O3B: "O3b Networks",
  ORB: "ORBCOMM",
  PAKI: "Pakistan",
  PERU: "Peru",
  POL: "Poland",
  POR: "Portugal",
  PRC: "China",
  PRY: "Paraguay",
  PRES: "China/European Space Agency (ESA)",
  QAT: "Qatar",
  RASC: "RascomStar-QAF",
  ROC: "Taiwan",
  ROM: "Romania",
  RP: "Philippines",
  RWA: "Rwanda",
  SAFR: "South Africa",
  SAUD: "Saudi Arabia",
  SDN: "Sudan",
  SEAL: "Sea Launch",
  SES: "SES",
  SGJP: "Singapore/Japan",
  SING: "Singapore",
  SKOR: "South Korea",
  SPN: "Spain",
  STCT: "Singapore/Taiwan",
  SVN: "Slovenia",
  SWED: "Sweden",
  SWTZ: "Switzerland",
  TBD: "To Be Determined",
  THAI: "Thailand",
  TMMC: "Turkmenistan/Monaco",
  TUN: "Tunisia",
  TURK: "Turkey",
  UAE: "United Arab Emirates",
  UK: "United Kingdom",
  UKR: "Ukraine",
  UNK: "Unknown",
  URY: "Uruguay",
  US: "United States",
  USBZ: "United States/Brazil",
  VENZ: "Venezuela",
  VTNM: "Vietnam",
};

export const fromIsoA3ToSatCat: Record<string, string> =

{
  //AB: "Arab Satellite Communications Organization",
  //ABS: "Asia Broadcast Satellite",
  //AC: "Asia Satellite Telecommunications Company (ASIASAT)",
  DZA: "ALG",
  AGO: "ANG",
  ARG: "ARG",
  AUT: "ASRA",
  AUS: "AUS",
  AZE: "AZER",
  BEL: "BEL",
  BLR: "BELA",
  BMU: "BERM",
  BGD: "BGD",
  BTN: "BHUT",
  BOL: "BOL",
  BRA: "BRAZ",
  BGR: "BUL",
  CAN: "CA",
  //CHBZ: "China/Brazil",
  CHL: "CHLE",
  RUS: "CIS",
  COL: "COL",

  CRI: "CRI",
  CZE: "CZCH",
  DNK: "DEN",
  ECU: "ECU",
  EGY: "EGYP",
  //ESA: "European Space Agency",
  //ESRO: "European Space Research Organization",
  EST: "EST",
  //EUME: "European Organization for the Exploitation of Meteorological Satellites (EUMETSAT)",
  //EUTE: "European Telecommunications Satellite Organization (EUTELSAT)",
  //FGER: "France/Germany",
  FIN: "FIN",
  FRA: "FR",
  //FRIT: "France/Italy",
  DEU: "GER",
  GHA: "GHA",
  //GLOB: "Globalstar",
  GRC: "GREC",
  //GRSA: "Greece/Saudi Arabia",
  GTM: "GUAT",
  HUN: "HUN",
  //IM: "International Mobile Satellite Organization (INMARSAT)",
  IND: "IND",
  IDN: "INDO",
  IRN: "IRAN",
  IRQ: "IRAQ",
  //IRID: "Iridium",
  ISR: "ISRA",
  //ISRO: "Indian Space Research Organisation",
  //ISS: "International Space Station",
  ITA: "IT",
  //ITSO: "International Telecommunications Satellite Organization (INTELSAT)",
  JPN: "JPN",
  KAZ: "KAZ",
  KEN: "KEN",
  LAO: "LAOS",
  LKA: "LKA",
  LTU: "LTU",
  LUX: "LUXE",
  MAR: "MA",
  MYS: "MALA",
  MEX: "MEX",
  MMR: "MMR",
  MNG: "MNG",
  MUS: "MUS",
  //NATO: "North Atlantic Treaty Organization",
  NLD: "NETH",
  //NICO: "New ICO",
  NGA: "NIG",
  PRK: "NKOR",
  NOR: "NOR",
  NPL: "NPL",
  NZL: "NZ",
  //O3B: "O3b Networks",
  //ORB: "ORBCOMM",
  PAK: "PAK",
  PER: "PERU",
  POL: "POL",
  PRT: "POR",
  CHN: "PRC",
  PRY: "PRY",
  //PRES: "People's Republic of China/European Space Agency",
  QAT: "QAT",
  //RASC: "RascomStar-QAF",
  TWN: "ROC",
  ROU: "ROM",
  PHL: "PR",
  RWA: "RWA",
  ZAF: "SAFR",
  SAU: "SAUD",
  SDN: "SDN",
  //SEAL: "Sea Launch",
  //SES: "SES",
  //SGJP: "Singapore/Japan",
  SGP: "SING",
  KOR: "SKOR",
  ESP: "SPN",
  //STCT: "Singapore/Taiwan",
  SVN: "SVN",
  SWE: "SWED",
  CHE: "SWTZ",
  TBD: "TDB",
  //TBD: "To Be Determined",
  THA: "THAI",
  //TMMC: "Turkmenistan/Monaco",
  TUN: "TUN",
  TUR: "TURK",
  ARE: "UAE",
  GBR: "UK",
  UKR: "UKR",
  UNK: "UNK",
  //UNK: "Unknown",
  URY: "URY",
  USA: "US",
  //USBZ: "United States/Brazil",
  VEN: "VENZ",
  VNM: "VTNM"
}


export const ORBIT_TYPE_CODE_TO_FULL_NAME: Record<string, string> =
{
  LEO: "Low Earth Orbit", // that is orbitClass: "orbitType",
  MEO: "Medium Earth Orbit",
  GEO: "Geosynchronous Orbit",
  Elliptical: "Elliptical Orbit"
  //NGSO: "Non-Geostationary Orbit"
}

